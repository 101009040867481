import CustomButton from '@/common/CustomButton';
import CustomModal from '@/common/CustomModal';
import FormItem from '@/common/FormItem';
import { createUserInfo } from '@/pages/settings/redux/slice';
import { CaretDownFilled } from '@ant-design/icons';
import { Col, Form, Input, Row, message, Select } from 'antd';
//
import React from 'react';
import { useDispatch } from 'react-redux';
const { Option } = Select;

const AddModal = ({
  // handleSbgSearchChange,
  // all_SBGs,
  onCancel,
  isModalVisible,
  setIsModalOpen,
  handleGetAllApprovers,
  departments,
  locations,
  handleDepartmentSearchChange,
  handleLocationSearchChange,
  designations,
  handleDesignationSearchChange
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const onFinish = (values) => {
    dispatch(createUserInfo(values)).then((response) => {
      if (response.payload.success) {
        message.success(response.payload.message);
        setIsModalOpen(false);
        handleGetAllApprovers();
      } else {
        message.error(response.payload.message);
      }
    });
  };

  // const activeSBGs = all_SBGs?.filter((item) => item?.is_active);
  return (
    <CustomModal
      footer={
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          className="ant-form ant-form-vertical"
        >
          <Row>
            <Col className="text-left" span={12}>
              <CustomButton onClick={onCancel}>Cancel</CustomButton>{' '}
            </Col>
            <Col className="text-right" span={12}>
              <CustomButton
                /* onClick={onOk} */ type="primary"
                htmlType="submit"
              >
                Add
              </CustomButton>
            </Col>
          </Row>
        </Form>
      }
      onCancel={onCancel}
      visible={isModalVisible}
      title="Add User"
    >
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        className="ant-form ant-form-vertical"
      >
        <Row gutter={24}>
          <Col span={12}>
            <FormItem
              label={'Name'}
              name="first_name"
              // className={`${styles.astrick}`}
              rules={[
                {
                  required: true,
                  message: 'Enter name'
                }
              ]}
            >
              <Input placeholder="Enter your name" />
              {/* <Select
                showSearch
                filterOption={(input, option) =>
                  option?.children
                    ?.toLowerCase()
                    ?.includes(input?.toLowerCase())
                }
                onSearch={handleSbgSearchChange}
                suffixIcon={<CaretDownFilled />}
                placeholder="Select SBG"
              >
                {activeSBGs?.map((item, index) => (
                  <Option value={item.id} key={index}>
                    {item.sbg_name}
                  </Option>
                ))}
              </Select> */}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              label={'Email ID'}
              name="email"
              // className={`${styles.astrick}`}
              rules={[
                {
                  required: true,
                  message: 'Enter your email'
                }
              ]}
            >
              <Input placeholder="Enter Email ID" />
            </FormItem>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <FormItem
              label={'Designation'}
              name="designation_id"
              // className={`${styles.astrick}`}
              rules={[
                {
                  required: true,
                  message: 'Enter your designation'
                }
              ]}
            >
              {/* <Input placeholder="Enter your designation" /> */}
              <Select
                showSearch
                filterOption={(input, option) =>
                  option?.children
                    ?.toLowerCase()
                    ?.includes(input?.toLowerCase())
                }
                onSearch={handleDesignationSearchChange}
                suffixIcon={<CaretDownFilled />}
                placeholder="Select designation"
              >
                {designations?.map((item, index) => (
                  <Option value={item.id} key={index}>
                    {item.designation}
                  </Option>
                ))}
              </Select>
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              label={'Department'}
              name="department_id"
              // className={`${styles.astrick}`}
              rules={[
                {
                  required: true,
                  message: 'Select department'
                }
              ]}
            >
              {/* <Input placeholder="Enter SBU Name" /> */}
              <Select
                showSearch
                filterOption={(input, option) =>
                  option?.children
                    ?.toLowerCase()
                    ?.includes(input?.toLowerCase())
                }
                onSearch={handleDepartmentSearchChange}
                suffixIcon={<CaretDownFilled />}
                placeholder="Select department"
              >
                {departments?.map((item, index) => (
                  <Option value={item.id} key={index}>
                    {item.function_name}
                  </Option>
                ))}
              </Select>
            </FormItem>
          </Col>
        </Row>
        <Row gutter={24}>
          {/* <Col span={12}>
            <FormItem
              label={'Contact'}
              name="contact"
              // className={`${styles.astrick}`}
              rules={[
                {
                  required: false,
                  message: 'Enter your contact number'
                }
              ]}
            >
              <Input placeholder="Enter your contact number" />
            </FormItem>
          </Col> */}
          <Col span={12}>
            <FormItem
              label={'Location'}
              name="location_id"
              // className={`${styles.astrick}`}
              rules={[
                {
                  required: true,
                  message: 'Select location'
                }
              ]}
            >
              {/* <Input placeholder="Enter SBU Name" /> */}
              <Select
                showSearch
                filterOption={(input, option) =>
                  option?.children
                    ?.toLowerCase()
                    ?.includes(input?.toLowerCase())
                }
                onSearch={handleLocationSearchChange}
                suffixIcon={<CaretDownFilled />}
                placeholder="Select location"
              >
                {locations?.map((item, index) => (
                  <Option value={item.id} key={index}>
                    {item.location_name}
                  </Option>
                ))}
              </Select>
            </FormItem>
          </Col>
        </Row>
      </Form>
    </CustomModal>
  );
};

export default AddModal;
