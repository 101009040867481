import React, { useEffect, useState } from 'react';
import {
  Form,
  Space,
  Select,
  Col,
  // Alert,
  // Checkbox,
  Row,
  Radio,
  Divider,
  Input,
  message
} from 'antd';
import CustomButton from '@/common/CustomButton';
import CustomModal from '@/common/CustomModal';
import FormItem from '@/common/FormItem';
// import { ExclamationCircleFilled } from '@ant-design/icons';
// CaretDownFilled
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getAllSbgs, getAllSbus } from '../../../settings/redux/slice';
const Option = Select;
// import { numFormating } from 'utilities/helpers';
import styles from './index.module.less';

const AddStandardModal = ({
  // setSearchSubCategory,
  // setCategoryId,
  // onSearchSubCategory,
  form,
  // onSearchCategory,
  // selectedStatus,
  // setSelectedStatus,
  handleStandardCancel,
  isStandardModalVisible,
  loading,
  // pricebrackets,
  // categories,
  // handleCategory,
  // subcategories,
  handleSearchstandard,
  type,
  // projects,
  // onSearch,
  // onSelectProject,
  // onSelectBU,
  // onSearchbu,
  // bu
  // handleSubCategory
  // setCategory,
  category,
  // locations,
  plants,
  functions,
  onCategoryChange,
  subcategory,
  activity,
  // companyid,
  setCompanyId,
  setSearch,
  setDepartmentt,
  setPlant,
  get_IONumbers,
  setIonum,
  get_COSTNumber,
  setCostnum,
  setIOSearch,
  setCOSTSearch,
  setactivitySearch,
  setDepartmentSearch
  // ionum
}) => {
  const dispatch = useDispatch();
  const params = useParams();
  // const { Option } = Select;
  // const { all_SBUs, all_SBGs } = useSelector((state) => state.settings);
  // const [subSpecificity, setSubSpecificity] = useState();
  // const [isChecked, setIsChecked] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [ioData, setIoData] = useState({});
  const [costData, setCostData] = useState({});
  const [fieldsDisabled, setFieldsDisabled] = useState(false);
  const [ioBalanceAmount, setIoBalanceAmount] = useState(0);
  const [nfaAmount, setNfaAmount] = useState(0);
  const [isValidNfa, setIsValidNfa] = useState(true);

  // const toggleCheckbox = (e) => {
  //   setIsChecked(!isChecked);
  //   setIsCategoryActive(e.target.checked);
  // };
  const handleGetAllSBUs = () => {
    dispatch(getAllSbus());
  };

  const handleGetAllSbgs = () => {
    dispatch(getAllSbgs());
  };
  useEffect(() => {
    handleGetAllSBUs();
    handleGetAllSbgs();
  }, []);
  // const business = [
  //   {
  //     id: 1,
  //     name: 'Urban Infrastructure'
  //   },
  //   {
  //     id: 2,
  //     name: 'Energy & Industrial'
  //   },
  //   {
  //     id: 3,
  //     name: 'Corporate'
  //   }
  // ];

  console.log('activity', params.id);

  console.log('type', type);
  localStorage.setItem('type', type);
  const onFinish = (values) => {
    if (!isValidNfa) {
      message.error(
        'Please change the NFA Amount in the form before submitting'
      );
      return; // Prevent form submission
    }
    values['type'] = type;
    // values['category_id'] = values['category_id'];
    // values['sub_category_id'] = values['sub_category_id'];
    // values['sub_category_id'] = values['Sub-Category'];
    // values['category_id'] = values['Category'];
    handleSearchstandard(values);
    localStorage.setItem(
      'apiCallValues',
      JSON.stringify({
        // unit_id: values.unit_id,
        type: type,
        category_id: values.category_id,
        sub_category_id: values.sub_category_id,
        flow_id: params.id,
        plant_id: values.plant_id,
        department_id: values.department_id,
        nfa_amount: values.nfa_amount,
        balance_amount: values.balance_amount,
        io_no_id: values.io_no_id,
        io_amount: values.io_amount,
        io_balance_amount: values.io_balance_amount,
        cost_center: values.cost_center,
        activity: values.activity_id,
        cost_centr_id: values.cost_centr_id,
        cost_center_amount: values.cost_center_amount,
        cost_center_balance_amount: values.cost_center_balance_amount

        // project_id: values.project_id || null
      })
    );
    console.log('values', values);
  };

  // const activeProjects = projects?.filter((item) => item?.is_active);
  // const [isCategoryActive, setIsCategoryActive] = useState(true);

  // Update the handleSelect function to set the isCategoryActive state
  // const handleSelectCategory = (e) => {
  //   form.setFieldsValue({
  //     'Sub-Category': undefined,
  //     sbg_id: undefined,
  //     sbu_id: undefined,
  //     unit_id: undefined
  //   });
  // const selectedCategory = categories.find((item) => item.id === e);
  //   setSubSpecificity('');
  //   setCategoryId(e);
  //   setSearchSubCategory('');
  //   setIsCategoryActive(selectedCategory?.is_active);

  //   // Rest of your handleSelect logic...
  // };

  // const onSelectSubCategory = (e) => {
  //   const selectedSubCategory = subcategories.find((item) => item.id === e);
  //   console.log(selectedSubCategory.sub_specificity);

  //   setSubSpecificity(selectedSubCategory?.sub_specificity);
  // };
  // console.log(subSpecificity);
  // const onSearchCategory = (searchText) => {
  //   setCategory(
  //     categories.filter((categories) =>
  //       categories.name.toLowerCase().includes(searchText.toLowerCase())
  //     )
  //   );
  // };

  // Handle Category Change
  const handleCategoryChange = (e) => {
    const selectedValue = e.target.value;
    // setSelectedCategory(selectedValue); // Update local state
    console.log('selectedValue', selectedValue);

    // Find the category object from the category array using the selected id
    const selectedCategoryObj = category.find(
      (cat) => cat.id === selectedValue
    );
    form.setFieldsValue({ sub_category_id: undefined });
    // Get the category name like 'Capex' or 'Opex'
    const selectedCategoryName = selectedCategoryObj
      ? selectedCategoryObj.category_name
      : '';
    console.log('selectedCategoryName', selectedCategoryName);

    // Update the state with the selected category id
    // setSelectedCategory(selectedCategoryName);
    setSelectedCategory(selectedCategoryName);
    // setSelectedSubCategory(null); // Reset sub-category to null

    // onCategoryChange(selectedValue);
    if (onCategoryChange) {
      onCategoryChange(selectedValue);
    }
  };
  console.log('SelectedCategory', selectedCategory);

  const handleCompany = (value) => {
    setCompanyId(value);
    // setCompany(value);
  };
  const handlePlant = (value) => {
    setPlant(value);
  };
  // const handleionum = (value) => {
  //   setIonum(value);
  // };

  // Effect to trigger `handleNfaAmountChange` when `nfaAmount` and `ioBalanceAmount` are updated
  useEffect(() => {
    if (nfaAmount && ioBalanceAmount) {
      const fakeEvent = { target: { value: nfaAmount } }; // Create a fake event object
      handleNfaAmountChange(fakeEvent); // Trigger the NFA amount change logic
    }
  }, [nfaAmount, ioBalanceAmount]);

  const handleIONumChange = (value) => {
    setIonum(value);
    const selectedIO = get_IONumbers.find((io) => io.id === value);
    if (selectedIO) {
      setIoData(selectedIO); // Store selected I/O data
      setIoBalanceAmount(selectedIO.balance_amount);
      form.setFieldsValue({
        io_amount: selectedIO.amount,
        io_balance_amount: selectedIO.balance_amount
      });
      setFieldsDisabled(true); // Disable the fields

      console.log('nfaAmount', nfaAmount);
      if (nfaAmount) {
        const fakeEvent = { target: { value: nfaAmount } }; // Create a fake event object
        handleNfaAmountChange(fakeEvent); // Trigger the NFA amount change logic
      }
    }
  };
  console.log('ioData', ioData);

  const handleNfaAmountChange = (e) => {
    const nfa = parseFloat(e.target.value) || 0;
    setNfaAmount(nfa);

    // Update the balance_amount field dynamically
    // const calculatedBalance = ioBalanceAmount > 0 ? ioBalanceAmount - nfa : nfa;
    const calculatedBalance = ioBalanceAmount - nfa;
    console.log('calculatedBalance', calculatedBalance);
    console.log('ioBalanceAmount', ioBalanceAmount);
    console.log('nfa', nfa);
    // Validate balance amount
    if (nfa > ioBalanceAmount) {
      setIsValidNfa(false); // Mark as invalid
      message.error('NFA amount cannot exceed the I/O Balance Amount');
      return; // Exit to prevent setting invalid values
    }

    setIsValidNfa(true);
    console.log('calculatedBalance', calculatedBalance);
    form.setFieldsValue({
      // balance_amount: calculatedBalance > 0 ? calculatedBalance : 0
      balance_amount: calculatedBalance
    });
  };
  console.log('nfaAmount', nfaAmount);

  // Cost Center Data
  const handleCostNumChange = (value) => {
    setCostnum(value);
    const selectedCostnum = get_COSTNumber.find((cost) => cost.id === value);
    if (selectedCostnum) {
      setCostData(selectedCostnum); // Store selected I/O data
      setIoBalanceAmount(selectedCostnum.balance_amount);
      form.setFieldsValue({
        cost_center_amount: selectedCostnum.amount,
        cost_center_balance_amount: selectedCostnum.balance_amount
      });
      setFieldsDisabled(true); // Disable the fields

      console.log('nfaAmount', nfaAmount);
      if (nfaAmount) {
        const fakeEvent = { target: { value: nfaAmount } }; // Create a fake event object
        handleNfaAmountChange(fakeEvent); // Trigger the NFA amount change logic
      }
    }
  };
  console.log('CostData', costData);

  const handleDepartmentt = (value) => {
    setDepartmentt(value);
  };
  const handleSearch = (value) => {
    setSearch(value);
  };

  const handleIOSearch = (value) => {
    setIOSearch(value);
  };
  const handlecostSearch = (value) => {
    setCOSTSearch(value);
  };
  const handleActivitySearch = (value) => {
    setactivitySearch(value);
  };
  const handleDepartmenttSearch = (e) => {
    setDepartmentSearch(e);
  };

  // localStorage.setItem('type', type);

  return (
    <CustomModal
      // width={1000}
      footer={null}
      onCancel={handleStandardCancel}
      visible={isStandardModalVisible}
      title={`Initiate ${type} DoA envelope`}
      type={'standard'}
    >
      <Form
        form={form}
        layout="vertical"
        className="ant-form ant-form-vertical"
        onFinish={onFinish}
      >
        {/* {type !== 'standard' && (
          <Alert
            icon={<ExclamationCircleFilled />}
            showIcon
            message={<b>Caution</b>}
            className="mb-3"
            type="warning"
            description={
              <div
                style={{
                  fontWeight: 400,
                  fontSize: '12px',
                  marginLeft: '-40px'
                }}
              >
                Custom envelopes should only be utilized in the absence of a
                Standard DOA. Before proceeding with any custom DOA, ensure that
                you have thoroughly checked the Standard DOA. Bypassing the
                Standard DOA without proper justification may result in
                procedural errors and potential consequences.
              </div>
            }
          />
        )} */}
        {/* <FormItem
          label="Envelope Name"
          name="envelope_name"
          rules={[
            {
              required: true,
              message: 'Required field'
            }
          ]}
        >
          <Input placeholder="Enter name" />
        </FormItem> */}
        {/* <FormItem
          label="Business"
          name="business"
          rules={[
            {
              required: false,
              message: 'Select Business'
            }
          ]}
        >
          <Select
            suffixIcon={<CaretDownFilled />}
            placeholder="Select Business"
            // showSearch
          >
            {business.map((item, index) => {
              return (
                <Option value={item.id} key={index}>
                  {item.name}
                </Option>
              );
            })}
          </Select>
        </FormItem> */}

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Plant"
              name="plant_id"
              rules={[{ required: true, message: 'Select plant' }]}
            >
              <Select
                placeholder="Select plant"
                onChange={handlePlant}
                showSearch
                optionFilterProp="children"
                onSearch={handleSearch}
              >
                {/* <Option value="location1">Location 1</Option>
                <Option value="location2">Location 2</Option> */}
                {plants.map((plant) => (
                  <Option key={plant.id} value={plant.id}>
                    {plant.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Department"
              name="department_id"
              rules={[{ required: true, message: 'Select department' }]}
            >
              <Select
                placeholder="Select department"
                onChange={handleDepartmentt}
                showSearch
                optionFilterProp="children"
                onSearch={handleDepartmenttSearch}
              >
                {/* <Option value="function1">Function 1</Option>
                <Option value="function2">Function 2</Option> */}
                {functions.map((functions) => (
                  <Option key={functions.id} value={functions.id}>
                    {functions.function_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Category"
              name="category_id"
              rules={[{ required: true, message: 'Select a Category type' }]}
            >
              <Radio.Group
                onChange={handleCategoryChange}
                value={selectedCategory}
              >
                {/* <Radio value="Capex">Capex</Radio>
                <Radio value="P2P">P2P</Radio> */}
                {category.map((cat) => (
                  <Radio key={cat.id} value={cat.id}>
                    {cat.category_name}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Sub Category"
              name="sub_category_id"
              rules={[{ required: true, message: 'Select sub-category' }]}
            >
              <Select placeholder="Select sub-category">
                {/* <Option value="location1">Location 1</Option>
                <Option value="location2">Location 2</Option> */}
                {subcategory.map((subcategory) => (
                  <Option key={subcategory.id} value={subcategory.id}>
                    {subcategory.sub_category_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {/*  */}

          {/* Conditionally Render Based on Category */}
          {selectedCategory === 'Capex' && (
            <>
              <Col span={12}>
                <Form.Item
                  label="I/O No"
                  // name="io_no"
                  name="io_no_id"
                  rules={[{ required: true, message: 'Please enter I/O No' }]}
                >
                  {/* <Input placeholder="Enter I/O No" />  get_IONumbers */}
                  <Select
                    placeholder="Select I/O No"
                    // onChange={handleionum}
                    onChange={handleIONumChange}
                    showSearch
                    optionFilterProp="children"
                    onSearch={handleIOSearch}
                  >
                    {get_IONumbers.map((get_IONumber) => (
                      <Option key={get_IONumber.id} value={get_IONumber.id}>
                        {get_IONumber.number}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="I/O Amount"
                  name="io_amount"
                  rules={[
                    { required: true, message: 'Please enter I/O amount' }
                  ]}
                >
                  <Input
                    placeholder="Enter I/O amount"
                    disabled={fieldsDisabled}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="I/O Balance Amount"
                  name="io_balance_amount"
                  rules={[
                    {
                      required: false,
                      message: 'Please check I/O balance amount'
                    }
                  ]}
                >
                  <Input
                    placeholder="Please check I/O balance amount"
                    disabled={fieldsDisabled}
                    value={ioBalanceAmount}
                  />
                </Form.Item>
              </Col>
            </>
          )}

          {selectedCategory === 'Opex' && (
            <>
              <Col span={12}>
                <Form.Item
                  label="Cost Center"
                  // name="cost_center"
                  name="cost_centr_id"
                  rules={[
                    { required: true, message: 'Please enter cost center' }
                  ]}
                >
                  {/* <Input placeholder="Enter Cost Center" /> */}
                  <Select
                    placeholder="Select cost center number"
                    // onChange={handleionum}
                    onChange={handleCostNumChange}
                    showSearch
                    optionFilterProp="children"
                    onSearch={handlecostSearch}
                  >
                    {get_COSTNumber.map((get_COSTNumbe) => (
                      <Option key={get_COSTNumbe.id} value={get_COSTNumbe.id}>
                        {get_COSTNumbe.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Cost Center Amount"
                  name="cost_center_amount"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter cost center amount'
                    }
                  ]}
                >
                  <Input
                    placeholder="Enter cost center amount"
                    disabled={fieldsDisabled}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Cost Center Balance Amount"
                  name="cost_center_balance_amount"
                  rules={[
                    {
                      required: false,
                      message: 'Please check cost center balance amount'
                    }
                  ]}
                >
                  <Input
                    placeholder="Please check cost center balance amount"
                    disabled={fieldsDisabled}
                    value={ioBalanceAmount}
                  />
                </Form.Item>
              </Col>
            </>
          )}

          {/* <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Purchase Type"
              name="purchaseType"
              rules={[
                { required: true, message: 'Please select a purchase type' }
              ]}
            >
              <Radio.Group>
                <Radio value="material">Material</Radio>
                <Radio value="service">Service</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={12}>
              <Form.Item
                label="Minimum Value"
                name="min_value"
                rules={[
                  {
                    required: true,
                    message: 'Please enter minimum value'
                  }
                ]}
              >
                <Input placeholder="Enter minimum value" />
              </Form.Item>
            </Col>
          </Row> */}
          {/* <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Budget Line"
              name="budget_line"
              rules={[
                {
                  required: true,
                  message: 'Please enter budget line'
                }
              ]}
            >
              <Input placeholder="Enter budget line" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Budget Amount"
              name="budget_amount"
              rules={[
                { required: true, message: 'Please enter the budget amount' }
              ]}
            >
              <Input placeholder="Enter budget amount" />
            </Form.Item>
          </Col>
          </Row> */}
          <Col span={12}>
            <Form.Item
              label="NFA Amount"
              name="nfa_amount"
              rules={[
                {
                  required: true,
                  message: 'Please enter NFA amount'
                }
              ]}
            >
              <Input
                placeholder="Enter NFA amount"
                onChange={handleNfaAmountChange}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Balance Amount"
              name="balance_amount"
              rules={[
                { required: true, message: 'Please enter balance amount' }
              ]}
            >
              {/* <Input placeholder="Enter balance amount" disabled /> {selectedCategory === 'Capex' && ( */}
              <Input
                // size="large"
                disabled
                placeholder="Total invoice amount"
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Activity"
              name="activity_id"
              rules={[{ required: true, message: 'Select activity' }]}
            >
              <Select
                placeholder="Select activity"
                onChange={handleCompany}
                showSearch
                optionFilterProp="children"
                onSearch={handleActivitySearch}
              >
                {/* <Option value="location1">Location 1</Option>
                <Option value="location2">Location 2</Option> */}
                {activity.map((activity) => (
                  <Option key={activity.id} value={activity.id}>
                    {activity.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          {/* <Col span={12}>
            <Form.Item
              label="Minimum Value"
              name="min_value"
              rules={[
                {
                  required: true,
                  message: 'Please enter minimum value'
                }
              ]}
            >
              <Input placeholder="Enter minimum value" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Maximum Value"
              name="max_value"
              rules={[
                {
                  required: true,
                  message: 'Please enter maximum value'
                }
              ]}
            >
              <Input placeholder="Enter maximum value" />
            </Form.Item>
          </Col>*/}
        </Row>

        <Divider />
        {/* <FormItem
          label="Category"
          name="Category"
          rules={[
            {
              required: true,
              message: 'Select Category'
            }
          ]}
        >
          <Select
          suffixIcon={<CaretDownFilled />}
          placeholder="Select Category"
          showSearch
          onSelect={handleSelectCategory}
          onSearch={onSearchCategory}
          onChange={(e) => handleCategory(e)}
          filterOption={(inputValue, option) =>
            option.props.children
              .toUpperCase()
              .indexOf(inputValue.toUpperCase()) !== -1
          }
          >
            {categories
              .filter((item) => item.new_cat_doa)
              .map((item, index) => {
                return (
                  <Option value={item.id} key={index}>
                    {item.is_active
                      ? item.name
                      : item.name + ' - (Deactivated)'}
                  </Option>
                );
              })}
          </Select>
        </FormItem> */}
        {/* <FormItem
          label="Sub-Category"
          name="Sub-Category"
          rules={[
            {
              required: true,
              message: 'Select Sub-Category'
            }
          ]}
        >
          <Select
            dropdownStyle={{ whiteSpace: 'normal', wordBreak: 'break-word' }}
            dropdownRender={(menu) => (
              <div style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>
                {menu}
              </div>
            )}

            suffixIcon={<CaretDownFilled />}
            placeholder="Select Sub-Category"
            onChange={(e) => handleSubCategory(e)}
            showSearch
            onSearch={onSearchSubCategory}
            onSelect={(e, option) => onSelectSubCategory(e, option)}
            filterOption={(inputValue, option) =>
              option?.props?.children
                ?.toUpperCase()
                ?.indexOf(inputValue?.toUpperCase()) !== -1
            }
          >
            {subcategories
              .filter((item) => item.new_sub_cat_doa)
              .map((item, index) => {
                return (
                  <Option value={item.id} key={index}>
                    {item.name}
                  </Option>
                );
              })}
          </Select>
        </FormItem> */}
        {/* {subSpecificity === 'bu_specific' ||
        subSpecificity === 'sbg_specific' ||
        subSpecificity === 'sbu_specific' ? (
          <FormItem
            label="Business Unit"
            name="unit_id"
            rules={[
              {
                required: true,
                message: 'Select Business Unit'
              }
            ]}
          >
            <Select
              showSearch
              onSearch={onSearchbu}
              placeholder="Select Business Unit"
              onSelect={onSelectBU}
              filterOption={(inputValue, option) =>
                option.props.children
                  .toUpperCase()
                  .indexOf(inputValue.toUpperCase()) !== -1
              }
            >
              {bu?.map((item, index) => {
                return (
                  <Option value={item.id} key={index}>
                    {item.is_active
                      ? item.name
                      : `${item.name} - (Deactivated)`}
                  </Option>
                );
              })}
            </Select>
          </FormItem>
        ) : null} */}
        {/* {subSpecificity === 'project_specific' && (
          <FormItem
            label="Projects"
            name="project_id"
            rules={[
              {
                required: true,
                message: 'Select Project'
              }
            ]}
          >
            <Select
              showSearch
              // style={{ width: 200 }}
              onSearch={onSearch}
              placeholder="Select Project"
              onSelect={onSelectProject}
              filterOption={(inputValue, option) =>
                option.props.children
                  .toUpperCase()
                  .indexOf(inputValue.toUpperCase()) !== -1
              }
            >
              {activeProjects?.map((item, index) => {
                return (
                  <Option value={item.id} key={index}>
                    {item.project}
                  </Option>
                );
              })}
            </Select>
          </FormItem>
        )} */}
        {/* {subSpecificity === 'sbg_specific' && (
          <Col span={24}>
            <FormItem
              label={'SBG'}
              name="sbg_id"
              // className={`${styles.astrick}`}
              rules={[
                {
                  required: true,
                  message: 'Please select SBG'
                }
              ]}
            >
              <Select
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                suffixIcon={<CaretDownFilled />}
                placeholder="Select SBG"
              >
                {' '}
                {all_SBGs?.map((item, index) => (
                  <Select.Option key={index} value={item.id}>
                    {item.sbg_name}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
          </Col>
        )}{' '} */}
        {/* {subSpecificity === 'sbu_specific' && (
          <Col span={24}>
            <FormItem
              label={'SBU'}
              name="sbu_id"
              // className={`${styles.astrick}`}
              rules={[
                {
                  required: true,
                  message: 'Please select SBU'
                }
              ]}
            >
              <Select
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                suffixIcon={<CaretDownFilled />}
                placeholder="Select SBU"
              >
                {' '}
                {all_SBUs?.map((item, index) => (
                  <Select.Option key={index} value={item.id}>
                    {item.sbu_name}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
          </Col>
        )} */}
        {/* {type !== 'standard' && (
          <FormItem
            label=""
            name="checkbox"
            rules={[
              {
                required: false
              }
            ]}
          >
            <Row>
              <Col span={24}>
                <Checkbox
                  className="mr-1"
                  onChange={toggleCheckbox}
                  allowClear
                />{' '}
                <span style={{ fontWeight: 'lighter' }}>
                  I have verified the applicability of the Standard DOA for this
                  envelope and would like to proceed with the creation of a
                  custom DOA envelope.
                </span>
              </Col>
            </Row>
          </FormItem>
        )} */}

        <FormItem className={`${styles.footerstyle}`}>
          <Space>
            <CustomButton htmlType="button" onClick={handleStandardCancel}>
              Cancel
            </CustomButton>
            <CustomButton
              // disabled={
              //   type !== 'standard'
              //     ? !isCategoryActive || !isChecked
              //     : !isCategoryActive
              // }
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              Next
            </CustomButton>
          </Space>
        </FormItem>
      </Form>
    </CustomModal>
  );
};

export default AddStandardModal;
