import { Layout } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import styles from './components/InitiateEnvelopePage1/index.module.less';
import InititateEnvelopePage1 from './components/InitiateEnvelopePage1';
import InititateEnvelopePage2 from './components/InitiateEnvelopePage2';
import {
  getAllApprovers,
  createEnvelop,
  createStandardEnvelop,
  // getAllprojects,
  // getAllcustomenv,
  getAllenvByID,
  // getAllDepartments,
  // getAllCategories,
  // getAllSubCategories,
  // getAllVendors,
  getAllstandardenv,
  // getLocations,
  getfunctions,
  getPlants,
  getcategory,
  getactivity,
  getAllSubCategories,
  getIONum,
  getCOSTNum
} from './redux/slice';
// import { getIONum, getCOSTNum } from '../dashboard/redux/slice';
import { useDispatch, useSelector } from 'react-redux';
import { history } from 'app/history';
import { useParams, useLocation } from 'react-router-dom';
// import LocalStorage from 'utilities/localStorage';

const InitiateEnvelope = ({
  tempfileList,
  setInitiateEnv,
  setTempFileList
}) => {
  const {
    getAllApproverLoading,
    allApprovers,
    // projects,
    customenv,
    // departments,
    // pricebrackets,
    // categories,
    // subcategories,
    // vendors,
    // envelopeDataLoading,
    get_locations,
    get_functions,
    get_plants,
    get_category,
    get_subcategorylist,
    get_activity,
    subcategories,
    get_ioNum,
    get_costNum
  } = useSelector((state) => state.approvers);
  const dispatch = useDispatch();
  const [fileList, setFileList] = useState([]);
  const [approverSearch, setApproverSearch] = useState('');
  const [itemList, setItemList] = useState([{ id: 1, value: '', envtype: '' }]);
  const [type, setType] = useState(false);
  const [envtype, setEnvtype] = useState(false);
  const [fields, setFields] = useState([{ value: null }]);
  const [current, setCurrent] = useState(0);
  const [mergedPdfBlob, setMergedPdfBlob] = useState(null);
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const [initiate, setInitiate] = useState(false);
  const [emailmessage, setEmailmessage] = useState('');
  const [projectId, setProjectId] = useState(false);
  const [signOrder, setSignOrder] = useState(true);
  const [noti, setNoti] = useState();
  const [formatModal, setFormatModal] = useState(false);
  const [select, setSelect] = useState('');
  // const [category, setCategory] = useState('');
  const [formValues, setformValues] = useState({});
  const [searchproject, setSearchproject] = useState('');
  const [selectedProject, setSelectedProject] = useState([]);
  // const [searchbu, setSearchbu] = useState('');
  // const [vendorName, setVendorName] = useState();
  // const [cust, setCust] = useState([]);
  // const [subSearch, setSubSearch] = useState('');
  console.log('get_subcategorylist', subcategories);
  // console.log('get_costNum', get_costNum);
  console.log('get_ioNum', get_ioNum);
  // const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  // const [categorySearch, setCategorySearch] = useState('');

  // const handleGetCategories = () => {
  //   const payload = categorySearch != '' ? { search: categorySearch } : {};
  //   dispatch(getAllCategories(payload));
  // };

  // const handleGetSubCategories = (id) => {
  //   const payload = {
  //     sub_search: subSearch !== '' ? subSearch : '',
  //     category_id: id
  //   };

  //   dispatch(getAllSubCategories(payload));
  // };

  // useEffect(() => {
  //   if (selectedCategoryId !== null && selectedCategoryId !== undefined) {
  //     handleGetSubCategories(selectedCategoryId); // Fetch subcategories when
  //   } // subSearch changes.
  // }, [subSearch, selectedCategoryId]);

  // useEffect(() => {
  //   handleGetCategories();
  // }, [categorySearch]);
  console.log('customenv_Testing', customenv);
  console.log('getAllApproverLoading', getAllApproverLoading);

  const params = useParams();
  const location = useLocation();
  console.log('location', location);
  const env_type = new URLSearchParams(location.search).get('type');
  const env_mode = new URLSearchParams(location.search).get('mode');
  // console.log('env_mode', env_mode);
  console.log('env_type', env_type);
  useEffect(() => {
    if (env_type === 'custom' && env_type !== null) {
      setSignOrder(true);
      // handleGetCustomEnv();
      handleGetStandardEnv();
    }
    if (env_type === 'standard' && env_type !== null) {
      setSignOrder(true);
      handleGetStandardEnv();
    }
    if (env_mode === 'edit' && env_mode !== null) {
      setSignOrder(true);
      handleGetEnv();
    }
  }, []);

  useEffect(() => {
    // handlegetLocations();
    handlegetfunctions();
    handlegetPlants();
    handlegetcategory();
    handlegetactivity();
    handlegetIONum();
    handlegetCOSTNum();
  }, []);

  // const handlegetLocations = () => {
  //   dispatch(getLocations());
  // };

  const handlegetfunctions = () => {
    dispatch(getfunctions());
    dispatch(getPlants());
    dispatch(getcategory());
    dispatch(getactivity());
    dispatch(getAllSubCategories());
    dispatch(getIONum());
    dispatch(getCOSTNum());
  };

  const handlegetPlants = () => {
    dispatch(getPlants());
  };
  const handlegetcategory = () => {
    dispatch(getcategory());
    dispatch(getAllSubCategories());
  };

  const handlegetactivity = () => {
    dispatch(getactivity());
  };

  const handlegetIONum = () => {
    dispatch(getIONum());
  };

  const handlegetCOSTNum = () => {
    dispatch(getCOSTNum());
  };
  // useEffect(() => {
  //   const payload = { search: searchbu };
  //   dispatch(getAllDepartments(payload));
  // }, [searchbu]);

  // useEffect(() => {
  //   handleGetAllProjects();
  // }, [searchproject]);

  // useEffect(() => {
  //   dispatch(getAllVendors(vendorName));
  // }, [vendorName]);

  const approverPayload = useMemo(() => {
    return { type: 'users', search: approverSearch };
  }, [approverSearch]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      dispatch(getAllApprovers(approverPayload));
    }, 500);

    // Cleanup the timeout to avoid unnecessary dispatch if the component unmounts or the dependency changes
    return () => clearTimeout(timeoutId);
  }, [dispatch, approverPayload]);

  // const handleCategory = (e) => {
  //   const payload = {
  //     sub_search: subSearch,
  //     category_id: e
  //   };
  //   dispatch(getAllSubCategories(payload));
  // };

  // useEffect(() => {
  //   handleCategory(selectedCategoryId);
  // }, [subSearch, selectedCategoryId]);

  // const handleCategorySearch = (e) => {
  //   setCategorySearch(e);
  // };

  // const handleSubCategorySearch = (e) => {
  //   setSubSearch(e);
  // };

  // const handleGetCustomEnv = () => {
  //   const payload = {
  //     id: params.id,
  //     unit_id: apiCallVal.unit_id,
  //     category_id: apiCallVal.category_id,
  //     sub_category_id: apiCallVal.sub_category_id,
  //     project_id: apiCallVal.project_id
  //   };
  //   console.log('payloadAPI', payload);

  //   // dispatch(getAllcustomenv(payload)).then((response) => {
  //   //   setCust(response.payload.data);
  //   // });
  // };
  // const handleGetAllProjects = () => {
  //   const payload = {
  //     search: searchproject
  //   };
  //   dispatch(getAllprojects(payload));
  // };

  // const apiCallVal = LocalStorage.getItem('apiCallValues');

  const handleGetStandardEnv = () => {
    const payload = {
      id: params.id
      // unit_id: apiCallVal.unit_id,
      // category_id: apiCallVal.category_id,
      // sub_category_id: apiCallVal.sub_category_id,
      // plant_id: apiCallVal.plant_id,
      // department_id: apiCallVal.department_id,
      // nfa_amount: apiCallVal.nfa_amount,
      // balance_amount: apiCallVal.balance_amount,
      // activity: apiCallVal.activity
      // project_id: apiCallVal.project_id
    };
    console.log('payloadAPI', payload);
    dispatch(getAllstandardenv(payload));
  };

  const handleGetEnv = () => {
    dispatch(getAllenvByID(params.id));
  };

  console.log(
    'customenv?.approvers',
    // customenv[0]?.designations[0]?.designation_mappings[0]?.user.email
    customenv[0]?.approvers
  );

  useEffect(() => {
    if (
      env_type === 'custom' ||
      env_type === 'standard' ||
      env_mode === 'edit'
    ) {
      if (customenv[0]?.approvers) {
        console.log(
          'Approvers data:',
          // customenv[0]?.designations[0]?.designation_mappings[0]?.user
          customenv[0]?.approvers
        );
        let selectedOption = [];
        let itemList = [];
        customenv[0]?.approvers.forEach((item) => {
          selectedOption = [
            ...selectedOption,
            {
              name: item.display_name,
              email: item.email,
              job_title: item?.job_title,
              approver_id: item?.id
            }
          ];
          itemList = [
            ...itemList,
            {
              id: item.id,
              value: item.email,
              envtype: item.id,
              approver_id: item?.id
            }
          ];
        });
        //
        // -------- Backup Code
        // customenv[0]?.approvers.forEach((item) => {
        //   console.log('Processing approver:', item);
        //   selectedOption = [
        //     ...selectedOption,
        //     {
        //       name: item.display_name || 'No Name',
        //       email: item.email,
        //       job_title: item?.job_title
        //     }
        //   ];
        //   itemList = [
        //     ...itemList,
        //     { id: item.id, value: item.email, envtype: item.id }
        //   ];
        // });
        //  ------  Backup code done
        // const user =
        //   customenv[0]?.designations[0]?.designation_mappings[0]?.user;

        // console.log('Processing approver:', user); // Debugging log
        // Since user is a single object, access its properties directly
        // selectedOption = [
        //   {
        //     name: user.display_name || 'No Name', // Fallback for name
        //     email: user.email || 'No Email', // Fallback for email
        //     job_title: user?.job_title || 'No Job Title' // Fallback for job title
        //   }
        // ];

        // itemList = [
        //   {
        //     id: user.id || 'No ID', // Fallback for ID
        //     value: user.email || 'No Email',
        //     envtype: user.id || 'No EnvType'
        //   }
        // ];
        setSelectedOption(selectedOption);
        setItemList(itemList);
      }
    }
  }, [customenv]);
  // console.log('onSelect', onSelect);

  const handleFormSubmit = () => {
    setInitiate(true);
    let updatd = [];
    console.log('selectedOption', selectedOption);
    selectedOption.map((item, index) => {
      var obj = {};
      obj['name'] = item.display_name;
      obj['email'] = item.email;
      obj['approver_id'] = item.approver_id;
      signOrder ? (obj['sequence'] = index + 0) : '';
      obj['sign_in'] = index === 0 ? true : false;
      updatd.push(obj);
    });
    // console.log('fileList in Parent', mergedPdfBlob);
    let formData = new FormData();
    /****** Blob to file converter */
    // const blob = new Blob([mergedPdfBlob], {
    //   type: 'application/pdf; '
    // });

    // blob.name = `${localStorage.getItem('envelope_name')}.pdf`;
    // blob instanceof File;
    // const myFile = new File(
    //   [blob],
    //   `${localStorage.getItem('envelope_name')}.pdf`,
    //   {
    //     type: blob.type
    //   }
    // );
    /************** End */
    console.log('signOrder', signOrder);
    console.log('formValues', formValues);
    console.log('initiateenv_type', env_type);
    console.log('localStorage', localStorage.getItem('apiCallValues'));

    fileList.forEach((file) => {
      formData.append(`documents`, file);
    });
    console.log('type', env_type);

    const apiCallValues = localStorage.getItem('apiCallValues');
    formData.append('type', env_type ? env_type : 'ad-hoc');
    console.log('formValues?.message', formValues?.message);
    console.log('setformValues', formValues);
    if (env_type !== 'standard' && env_type !== 'custom') {
      formData.append('title', formValues?.title);
      formData.append('plant_id', formValues?.plant_id);
      formData.append('department_id', formValues?.department_id);
      formData.append('approvers', JSON.stringify(updatd));
      formData.append('description', formValues?.description);
      formData.append('message', formValues?.message);
      formData.append('nfa_amount', formValues?.nfa_amount);
      formData.append('balance_amount', formValues?.balance_amount);
      formData.append('signing_order', signOrder);
    } else {
      formData.append('approvers', JSON.stringify(updatd));
      formData.append('description', formValues?.description);
      formData.append('message', formValues?.message);
      formData.append('flow_id', localStorage.getItem('flow_id'));
      formData.append('signing_order', signOrder);
      formData.append('title', localStorage.getItem('envelope_name'));
      if (apiCallValues) {
        const parsedValues = JSON.parse(apiCallValues);
        formData.append('category_id', parsedValues.category_id);
        formData.append('sub_category_id', parsedValues.sub_category_id);
        formData.append('plant_id', parsedValues.plant_id);
        formData.append('department_id', parsedValues.department_id);
        formData.append('nfa_amount', parsedValues.nfa_amount);
        formData.append('balance_amount', parsedValues.balance_amount);
        formData.append('io_no_id', parsedValues.io_no_id);
        formData.append('io_amount', parsedValues.io_amount);
        formData.append('activity', parsedValues.activity);
        formData.append('cost_center', parsedValues.cost_center);
      }
    }

    // formData.append('documents', mergedPdfBlob);
    // formData.append('documents', fileList[0]);
    // formData.append('budget_type', formValues?.budget_type);
    // formData.append('purchase_type', formValues?.purchase_type);
    // formData.append('value', formValues?.value);
    // formData.append('category_id', formValues?.category_id);
    // formData.append('sub_category_id', formValues?.sub_category_id);
    // formData.append('min_value', formValues?.min_value);
    // formData.append('max_value', formValues?.max_value);
    // formData.append('budget_line', formValues?.budget_line);
    // formData.append('budget_amount', formValues?.budget_amount);

    // formData.append('min_value', formValues?.min_value);
    // formData.append('max_value', formValues?.max_value);
    // formValues?.project_code !== undefined
    //   ? formData.append('project_id', formValues?.project_code)
    //   : '';
    // formData.append('category_id', formValues?.Category);
    // formData.append('sub_category_id', formValues.sub_category_id);
    // formData.append('price_bracket_id', formValues?.price_bracket_id);
    // formData.append('min_price', formValues?.min_price);
    // formData.append('max_price', formValues?.max_price);
    // formData.append('unit_id', formValues?.unit_id);
    // formData.append('purchase_work_order', formValues?.purchase_work_order);
    // formData.append('purchase_requistion', formValues?.purchase_requistion);
    // formData.append('budget_availablity', formValues?.budget_availablity);
    // formData.append('main_group', formValues?.main_group);
    // formData.append('minor_group', formValues?.minor_group);
    // formData.append('vendor_name', formValues?.vendor_name);
    // formData.append('vendor_erp_code', formValues?.vendor_erp_code);
    // formData.append('total_value', formValues?.total_value);

    const dispatchAction =
      env_type === 'standard' || env_type === 'custom'
        ? createStandardEnvelop
        : createEnvelop;

    // dispatch(createEnvelop(formData)).then((response) => {
    dispatch(dispatchAction(formData)).then((response) => {
      if (response.payload.success) {
        setInitiate(false);
        setSignOrder(false);
        history.push('/dashboard');
      } else {
        setInitiate(false);
      }
    });
  };

  // const handlegetVendors = (data) => {

  // }
  const handleSave = () => {
    let updatd = [];
    selectedOption.map((item, index) => {
      var obj = {};
      obj['name'] = item.display_name;
      obj['email'] = item.email;
      obj['approver_id'] = item.id;
      obj['sequence'] = index;
      obj['sign_in'] = index === 0 ? true : false;
      updatd.push(obj);
    });
    console.log('localStorage', emailmessage);
    console.log('setformValues', formValues);
    let formData = new FormData();
    formData.append('title', localStorage.getItem('envelope_name'));
    formData.append('description', localStorage.getItem('doa_description'));
    // formData.append('project_id', localStorage.getItem('project_id'));
    formData.append('approvers', JSON.stringify(updatd));
    formData.append('envelope_type', localStorage.getItem('envelope_type'));
    formData.append('message', emailmessage);
    // formData.append('emailmessage', localStorage.getItem('emailmessage'));
    // formData.append('category_id', localStorage.getItem('category_id'));
    // formData.append('sub_category_id', localStorage.getItem('sub_category_id'));
    // formData.append('min_value', localStorage.getItem('min_value'));
    // formData.append('max_value', localStorage.getItem('max_value'));

    for (let i = 0; i < tempfileList.length; i++) {
      formData.append(`file`, tempfileList[i].originFileObj);
    }
    dispatch(createStandardEnvelop(formData)).then((response) => {
      if (response.payload.success) {
        setSignOrder(false);
        setInitiateEnv(false);
        setTempFileList([]);
        history.push('/admin-dashboard');
      } else {
        setInitiate(false);
      }
    });
  };
  console.log('setformValues', formValues);
  return (
    <>
      <Layout className={`${styles.layout}`}>
        <>
          {current == 0 ? (
            <div className="steps-content">
              <InititateEnvelopePage1
                // envelopeDataLoading={envelopeDataLoading}
                setApproverSearch={setApproverSearch}
                // setSelectedCategoryId={setSelectedCategoryId}
                // handleGetSubCategories={handleGetSubCategories}
                // handleCategorySearch={handleCategorySearch}
                // handleSubCategorySearch={handleSubCategorySearch}
                // setCategorySearch={setCategorySearch}
                // setSubSearch={setSubSearch}
                noti={noti}
                setNoti={setNoti}
                select={select}
                setSelect={setSelect}
                setFileList={setFileList}
                fileList={fileList}
                itemList={itemList}
                setItemList={setItemList}
                type={type}
                setType={setType}
                envtype={envtype}
                setEnvtype={setEnvtype}
                current={current}
                setCurrent={setCurrent}
                fields={fields}
                setFields={setFields}
                setMergedPdfBlob={setMergedPdfBlob}
                getAllApproverLoading={getAllApproverLoading}
                allApprovers={allApprovers}
                options={options}
                setOptions={setOptions}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                emailmessage={emailmessage}
                setEmailmessage={setEmailmessage}
                handleSave={handleSave}
                // projects={projects}
                projectId={projectId}
                setProjectId={setProjectId}
                setSignOrder={setSignOrder}
                signOrder={signOrder}
                customenv={
                  env_type === 'custom' || env_type === 'standard'
                    ? customenv
                    : ''
                }
                env_type={env_type}
                formatModal={formatModal}
                setFormatModal={setFormatModal}
                // departments={departments}
                // pricebrackets={pricebrackets}
                // categories={categories}
                // category={category}
                // setCategory={setCategory}
                // handleCategory={handleCategory}
                // subcategories={subcategories}
                formValues={formValues}
                setformValues={setformValues}
                searchproject={searchproject}
                setSearchproject={setSearchproject}
                selectedProject={selectedProject}
                setSelectedProject={setSelectedProject}
                // handlegetVendors={handlegetVendors}
                // setVendorName={setVendorName}
                // vendors={vendors}
                // cust={cust}
                // setSearchbu={setSearchbu}
                // vendors={vendors}
                // setVendors={setVendors}
                locations={get_locations}
                functions={get_functions}
                plants={get_plants}
                category={get_category}
                activity={get_activity}
                subcategory={get_subcategorylist}
                get_IONumbers={get_ioNum}
                get_COSTNumber={get_costNum}
              />
            </div>
          ) : (
            <div className="steps-content">
              <InititateEnvelopePage2
                setFileList={setFileList}
                fileList={fileList}
                itemList={itemList}
                setItemList={setItemList}
                type={type}
                setType={setType}
                envtype={envtype}
                setEnvtype={setEnvtype}
                current={current}
                setCurrent={setCurrent}
                mergedPdfBlob={mergedPdfBlob}
                selectedOption={selectedOption}
                handleFormSubmit={handleFormSubmit}
                initiate={initiate}
                setInitiate={setInitiate}
                emailmessage={emailmessage}
                // projects={projects}
                projectId={projectId}
                setProjectId={setProjectId}
                setSignOrder={setSignOrder}
                customenv={
                  env_type === 'custom' || env_type === 'standard'
                    ? customenv
                    : ''
                }
                env_type={env_type}
                locations={get_locations}
                functions={get_functions}
                plants={get_plants}
                category={get_category}
                activity={get_activity}
                subcategory={get_subcategorylist}
                get_IONumbers={get_ioNum}
                get_COSTNumber={get_costNum}
              />
            </div>
          )}
        </>
      </Layout>
    </>
  );
};

export default InitiateEnvelope;
