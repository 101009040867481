import CustomButton from '@/common/CustomButton';
import CustomModal from '@/common/CustomModal';
import FormItem from '@/common/FormItem';
import { editUserDetails } from '@/pages/settings/redux/slice';
import { Col, Form, Input, Row, message, Select } from 'antd';
// Select, Switch
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// import styles from './index.module.less';
import { CaretDownFilled } from '@ant-design/icons';
const { Option } = Select;

const EditModal = ({
  // handleSbgSearchChange,
  // all_SBGs,
  setId,
  id,
  // sbuById,
  onCancel,
  isEditModalVisible,
  setIsEditModalOpen,
  handleGetAllApprovers,
  getuserDetails,
  departments,
  locations,
  handleDepartmentSearchChange,
  handleLocationSearchChange,
  designations,
  handleDesignationSearchChange
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isActive, setIsActive] = useState(getuserDetails?.is_active);

  console.log('getuserDetails', getuserDetails);

  useEffect(() => {
    setId(getuserDetails?.id);
    setIsActive(getuserDetails?.is_active);
    /*  setAppId(flowData.app_id); */
    form.setFieldsValue({
      // sbu_name: getuserDetails?.sbu_name,
      // sbg_master_id:
      //   getuserDetails?.sbg_master?.is_active === false
      //     ? undefined
      //     : getuserDetails?.sbg_master_id,
      // is_active: getuserDetails?.is_active

      first_name: getuserDetails?.display_name,
      email: getuserDetails?.email,
      designation_id: getuserDetails?.designations?.[0]?.designation,
      department_id: getuserDetails?.departments?.[0]?.id,
      contact: getuserDetails?.contact,
      location_id: getuserDetails?.locations?.[0]?.id
    });
  }, [getuserDetails, form]);

  const onFinish = (values) => {
    values['id'] = id;
    values['action'] = isActive ? 'activate' : 'deactivate';
    dispatch(editUserDetails(values)).then((response) => {
      if (response.payload.success) {
        message.success(response.payload.message);
        setIsEditModalOpen(false);
        handleGetAllApprovers();
      } else {
        message.error(response.payload.message);
      }
    });
  };

  // const handleSwitchChange = (checked) => {
  //   setIsActive(checked);
  // };
  // const activeSBGs = getuserDetails?.filter((item) => item?.is_active);

  return (
    <CustomModal
      footer={
        <Form
          onFinish={onFinish}
          form={form}
          layout="vertical"
          className="ant-form ant-form-vertical"
        >
          <Row>
            <Col className="text-left" span={12}>
              <CustomButton onClick={onCancel}>Cancel</CustomButton>{' '}
            </Col>
            <Col className="text-right" span={12}>
              <CustomButton type="primary" htmlType="submit">
                Edit
              </CustomButton>
            </Col>
          </Row>
        </Form>
      }
      onCancel={onCancel}
      visible={isEditModalVisible}
      title="Edit User Details"
    >
      <Form
        onFinish={onFinish}
        form={form}
        layout="vertical"
        className="ant-form ant-form-vertical"
      >
        <Row gutter={24}>
          <Col span={12}>
            <FormItem
              label={'Name'}
              name="first_name"
              // className={`${styles.astrick}`}
              rules={[
                {
                  required: true,
                  message: 'Please enter'
                }
              ]}
            >
              <Input placeholder="Enter your name" />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              label={'Email ID'}
              name="email"
              // className={`${styles.astrick}`}
              rules={[
                {
                  required: true,
                  message: 'Please enter email'
                }
              ]}
            >
              <Input placeholder="Enter your email" />
            </FormItem>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={12}>
            <FormItem
              label={'Designation'}
              name="designation_id"
              // className={`${styles.astrick}`}
              rules={[
                {
                  required: true,
                  message: 'Enter your designation'
                }
              ]}
            >
              {/* <Input placeholder="Enter your designation" /> */}
              <Select
                showSearch
                filterOption={(input, option) =>
                  option?.children
                    ?.toLowerCase()
                    ?.includes(input?.toLowerCase())
                }
                onSearch={handleDesignationSearchChange}
                suffixIcon={<CaretDownFilled />}
                placeholder="Select designation"
              >
                {designations?.map((item, index) => (
                  <Option value={item.id} key={index}>
                    {item.designation}
                  </Option>
                ))}
              </Select>
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              label={'Department'}
              name="department_id"
              // className={`${styles.astrick}`}
              rules={[
                {
                  required: true,
                  message: 'Select department'
                }
              ]}
            >
              {/* <Input placeholder="Enter SBU Name" /> */}
              <Select
                showSearch
                filterOption={(input, option) =>
                  option?.children
                    ?.toLowerCase()
                    ?.includes(input?.toLowerCase())
                }
                onSearch={handleDepartmentSearchChange}
                suffixIcon={<CaretDownFilled />}
                placeholder="Select department"
              >
                {departments?.map((item, index) => (
                  <Option value={item.id} key={index}>
                    {item.function_name}
                  </Option>
                ))}
              </Select>
            </FormItem>
          </Col>
        </Row>

        <Row gutter={24}>
          {/* <Col span={12}>
            <FormItem
              label={'Contact'}
              name="contact"
              // className={`${styles.astrick}`}
              rules={[
                {
                  required: false,
                  message: 'Enter your contact number'
                }
              ]}
            >
              <Input placeholder="Enter your contact number" />
            </FormItem>
          </Col> */}
          <Col span={12}>
            <FormItem
              label={'Location'}
              name="location_id"
              // className={`${styles.astrick}`}
              rules={[
                {
                  required: true,
                  message: 'Select location'
                }
              ]}
            >
              {/* <Input placeholder="Enter SBU Name" /> */}
              <Select
                showSearch
                filterOption={(input, option) =>
                  option?.children
                    ?.toLowerCase()
                    ?.includes(input?.toLowerCase())
                }
                onSearch={handleLocationSearchChange}
                suffixIcon={<CaretDownFilled />}
                placeholder="Select location"
              >
                {locations?.map((item, index) => (
                  <Option value={item.id} key={index}>
                    {item.location_name}
                  </Option>
                ))}
              </Select>
            </FormItem>
          </Col>
        </Row>

        {/* <Col span={24}>
            <FormItem
              label={'Active'}
              name="is_active"
              // className={`${styles.astrick}`}
              rules={[
                {
                  required: true,
                  message: 'Please select'
                }
              ]}
            >
              <div
                className={
                  isActive
                    ? `${styles.switcher_container}`
                    : `${styles.switcher_container1}`
                }
              >
                <Switch
                  disabled={!sbuById?.is_active}
                  checked={isActive}
                  onChange={handleSwitchChange}
                />
              </div>
            </FormItem>
          </Col> */}
      </Form>
    </CustomModal>
  );
};

export default EditModal;
