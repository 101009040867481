import CustomButton from '@/common/CustomButton';
import CustomModal from '@/common/CustomModal';
import FormItem from '@/common/FormItem';
import { createDesignation } from '@/pages/settings/redux/slice';
//  createCategory
import { Col, Form, Input, Row, message } from 'antd';

import React from 'react';
import { useDispatch } from 'react-redux';

const AddModal = ({
  onCancel,
  isModalVisible,
  setIsModalOpen,
  handleGetDesignations
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const onFinish = (values) => {
    dispatch(createDesignation(values)).then((response) => {
      if (response.payload.success) {
        message.success(response.payload.message);
        setIsModalOpen(false);
        handleGetDesignations();
      } else {
        message.error(response.payload.message);
      }
    });
  };
  return (
    <CustomModal
      footer={
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          className="ant-form ant-form-vertical"
        >
          <Row>
            <Col className="text-left" span={12}>
              <CustomButton onClick={onCancel}>Cancel</CustomButton>{' '}
            </Col>
            <Col className="text-right" span={12}>
              <CustomButton
                /* onClick={onOk} */ type="primary"
                htmlType="submit"
              >
                Add
              </CustomButton>
            </Col>
          </Row>
        </Form>
      }
      onCancel={onCancel}
      visible={isModalVisible}
      title="Add Designation"
    >
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        className="ant-form ant-form-vertical"
      >
        <Row>
          <Col span={24}>
            <FormItem
              label={'Designation Name'}
              name="designation"
              // className={`${styles.astrick}`}
              rules={[
                {
                  required: true,
                  message: 'Please enter name'
                }
              ]}
            >
              <Input placeholder="Enter Designation Name" />
            </FormItem>
          </Col>
        </Row>
      </Form>
    </CustomModal>
  );
};

export default AddModal;
