import React, { useState, useEffect } from 'react';
import { Card, Col, DatePicker, Form, Modal, Row, Spin, Select } from 'antd';
import styles from './index.module.less';
import FormItem from '@/common/FormItem';
import CustomButton from '@/common/CustomButton';
import Header from '@/common/Content/header';
import moment from 'moment';
import LocalStorage from 'utilities/localStorage';
import { useDispatch, useSelector } from 'react-redux';
import { getPlants } from '../../../dashboard/redux/slice';

const { RangePicker } = DatePicker;

const ReportPage = () => {
  const [dateRange, setDateRange] = useState([moment(), moment()]);
  const [downloadReports, setDownloadReports] = useState(false);
  const [search, setSearch] = useState('');
  const [plant, setPlant] = useState('');
  const [form] = Form.useForm();
  const Option = Select;

  const { get_plants } = useSelector((state) => state.dashboard);

  const dispatch = useDispatch();
  useEffect(() => {
    handlegetPlants();
  }, [search]);

  const handlegetPlants = () => {
    dispatch(getPlants());
  };

  const handlePlant = (value) => {
    setPlant(value);
  };
  console.log('plant', plant);

  const onFinish = (values) => {
    console.log('values', values);
    setDownloadReports(true);
    const fromDate = values.fromDate
      ? `${moment(values.fromDate[0]._d).format('YYYY-MM-DD')}`
      : '';
    const toDate = values.fromDate
      ? `${moment(values.fromDate[1]._d).format('YYYY-MM-DD')}`
      : '';
    const plant_id = values.plant_id ? values.plant_id : '';
    const user = LocalStorage.getItem('gnfa_user');

    const authToken = user?.accessToken;
    fetch(
      `${process.env.REACT_APP_API_URL}envelopes/download-csv?from_date=${fromDate}&to_date=${toDate}&plant_id=${plant_id}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/csv',
          Authorization: `Bearer ${authToken}`
        }
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `NFAreport.csv`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setDownloadReports(false);
      });
  };

  const handleSearch = (e) => {
    setSearch(e);
  };

  return (
    <>
      <Header
        justify="space-between"
        align="middle"
        title="Download Reports"
        buttons={[]}
        className="mb-3"
      />
      <Card style={{ height: '60vh' }} hoverable>
        <div className={styles.content_holder}>
          {' '}
          <div className={`mb-3 ${styles.sub_title} font-14`}>
            Download the reports here
          </div>
          <Form
            form={form}
            layout="vertical"
            className="ant-form ant-form-vertical"
            onFinish={onFinish}
          >
            <Row
              className="mt-4"
              gutter={{
                xs: 8,
                sm: 16,
                md: 24,
                lg: 32
              }}
            >
              <Col className="gutter-row" span={10}>
                <FormItem
                  label="Select start and end date"
                  name="fromDate"
                  rules={[
                    {
                      required: false,
                      message: 'Please enter date'
                    }
                  ]}
                >
                  <RangePicker
                    format={'YYYY-MM-DD'}
                    onChange={(x) => {
                      setDateRange(x);
                    }}
                    disabledDate={(current) => {
                      return current && current.valueOf() > Date.now();
                    }}
                    value={dateRange}
                    // separator={'-'}
                    style={{ width: '100%' }}
                  />
                </FormItem>
              </Col>

              <Col className="gutter-row" span={10}>
                <FormItem
                  label="Plant"
                  name="plant_id"
                  rules={[
                    {
                      required: false,
                      message: 'Please select plant'
                    }
                  ]}
                >
                  {/* <Input placeholder="Please select plant"></Input> */}
                  <Select
                    placeholder="Select plant"
                    onChange={handlePlant}
                    showSearch
                    optionFilterProp="children"
                    onSearch={handleSearch}
                  >
                    {/* <Option value="location1">Location 1</Option>
                <Option value="location2">Location 2</Option> */}
                    {get_plants.map((plant) => (
                      <Option key={plant.id} value={plant.id}>
                        {plant.name}
                      </Option>
                    ))}
                  </Select>
                </FormItem>
              </Col>
            </Row>
            <FormItem shouldUpdate>
              <CustomButton
                htmlType="submit"
                /*   onClick={handleDownload} */
                className={styles.export_button}
                type="primary"
              >
                Export
              </CustomButton>
            </FormItem>
            <Modal visible={downloadReports} footer={false} closable={false}>
              <p style={{ textAlign: 'center' }}>Downloading the report</p>
              <p style={{ textAlign: 'center' }}>
                <Spin />
              </p>
            </Modal>
          </Form>
        </div>
      </Card>
    </>
  );
};

export default ReportPage;
