import React, { useEffect, useState } from 'react';
import {
  Breadcrumb,
  Button,
  Checkbox,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  // Radio,
  Row,
  Select,
  message
} from 'antd';
import styles from './index.module.less';
import {
  CaretDownFilled,
  CaretDownOutlined,
  DeleteOutlined,
  HolderOutlined,
  PlusCircleFilled,
  RightOutlined
} from '@ant-design/icons';
import FormItem from '@/common/FormItem';
import CustomLayout from '@/common/Layout';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCategories } from '@/features/dashboard/redux/slice';
import {
  createDoaFlow,
  getAllDesignationsList,
  getAllSbgs
} from '@/pages/settings/redux/slice';
import { debounce } from 'lodash';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { history } from '@/app/history';
import {
  getPlants,
  getfunctions,
  getcategory,
  getsubCategory,
  getactivity
} from '../../../../dashboard/redux/slice';

import { getAllUsers } from '../../../../settings/redux/slice';
import { getAllDesignations } from '../../../redux/slice';

const { Option } = Select;
const CreateDOAPage = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { get_designationDetails } = useSelector((state) => state.settings);
  // all_Users

  const [rowErrors, setRowErrors] = useState({}); // Track errors for each row
  const [filteredUsersByRow, setFilteredUsersByRow] = useState({}); // Track filtered users for each row
  const [anyValue, setAnyValue] = useState(false);
  const [search] = useState('');
  const [designSearch, setDesignSearch] = useState('');
  const [isProjectActive, setIsProjectActive] = useState(null);
  const [subSearch, setSubSearch] = useState('');

  const [selectedCategoryId, setSelectedCategoryId] = useState();
  const [categorySearch, setCategorySearch] = useState('');
  const [formValues, setFormValues] = useState([
    { sequence: 1, user_id: '', designation_id: '' }
  ]);
  const [designationSearch, setDesignationSearch] = useState('');
  const [page] = useState(1);

  const handleGetAllApprovers = () => {
    let payload =
      search !== ''
        ? { search: search, page: page }
        : { search: search, page: page };
    //   , page: page
    dispatch(getAllUsers(payload));
  };

  useEffect(() => {
    handleGetAllApprovers();
  }, [search, page]);
  //   , page
  useEffect(() => {
    handleGetAllApprovers();
  }, []);

  const {
    get_plants,
    get_functions,
    get_category,
    get_subcategorylist,
    get_activity
  } = useSelector((state) => state.dashboard);

  const handleGetAllDesignations = () => {
    let payload = { search: designSearch };
    dispatch(getAllDesignationsList(payload));
  };

  useEffect(() => {
    handleGetAllDesignations();
  }, [designSearch]);

  useEffect(() => {
    dispatch(getAllSbgs());
  }, []);

  const handleDesignSearchChange = (e) => {
    setDesignSearch(e);
  };

  const onFinish = (values) => {
    const approver = formValues.map((item) => ({
      sequence: item.sequence,
      user_id: item.user_id || values[`user_id${item.sequence}`],
      designation_id:
        item.designation_id || values[`designation_id${item.sequence}`]
    }));

    values['approvers'] = approver;
    const { approvers, ...otherValues } = values;

    console.log('values', approver);
    const doaFlows = approvers?.map((approver, index) => ({
      // console.log('approver_id', approver.user_id?.value);
      sequence: index + 1,
      designation_id: approver.designation_id,
      approver_id: approver?.user_id?.value
    }));

    const payload = {
      plant_id: otherValues.plant_id,
      activity_id: otherValues.activity_id,
      department_id: otherValues.department_id,
      category_id: otherValues.category_id,
      sub_category_id: otherValues.sub_category_id,
      title: otherValues.title,
      max_value: otherValues?.max_price,
      min_value: otherValues?.min_price,
      any_value: anyValue,
      approvers: doaFlows
    };
    console.log('payload', payload);
    dispatch(createDoaFlow(payload)).then((res) => {
      if (res.payload.success) {
        message.success(res.payload.message);
        history.push('/settings/doa-flows');
      } else {
        message.error(res.payload.message);
      }
    });
  };

  const toggleCheckbox = (e) => {
    if (e.target.checked) {
      form.setFieldsValue({ min_price: 999, max_price: 999 });
    } else {
      form.setFieldsValue({ min_price: undefined, max_price: undefined });
    }
    setAnyValue(e.target.checked);
    console.log(e.target.checked);
  };

  const addInput = () => {
    console.log('formvalues', formValues);
    var highestValue = 1;
    for (var i = 1, len = formValues.length; i < len; i++) {
      var value = Number(formValues[i]['sequence']);
      if (value > highestValue) {
        highestValue = value;
      }
    }
    let id = highestValue + 1;
    setFormValues([
      ...formValues,
      { sequence: id, user_id: '', designation_id: '' }
    ]);
    const fieldName1 = `user_id${id}`;
    const fieldName = `designation_id${id}`;
    form.setFieldsValue({
      [fieldName]: undefined,
      [fieldName1]: undefined
    });
  };

  const handleRemoveFormItem = (sequence) => {
    if (sequence === 1) {
      // Do not remove sequence 0
      return;
    }

    console.log('Form Values Before Deletion:', formValues);
    console.log('Sequence to Remove:', sequence);

    const updatedValues = formValues.filter(
      (item) => item.sequence !== sequence
    );

    const updatedValuesWithNewSequences = updatedValues.map((item, index) => ({
      ...item,
      sequence: index + 1
    }));

    setFormValues(updatedValuesWithNewSequences);

    const fieldsToRemove = [`designation_id${sequence}`, `user_id${sequence}`];

    fieldsToRemove.forEach((field) => {
      form.setFieldValue(field, undefined);
      form.resetFields([field]);
    });

    const remainingFieldValues = updatedValuesWithNewSequences.reduce(
      (acc, item) => {
        acc[`designation_id${item.sequence}`] = form.getFieldValue(
          `designation_id${item.sequence}`
        );
        acc[`user_id${item.sequence}`] = form.getFieldValue(
          `user_id${item.sequence}`
        );
        return acc;
      },
      {}
    );

    form.setFieldsValue(remainingFieldValues);
  };

  const onChangeDesignation = (data, index) => {
    console.log('data123', data);
    const newState = formValues.map((obj) => {
      if (obj.sequence === index + 1) {
        return { ...obj, sequence: index + 1, user_id: data };
      }
      return obj;
    });

    setFormValues(newState);
  };

  //
  console.log('formValues', formValues);

  // const handleDragEnd = (result) => {
  //   console.log('result', result);
  //   if (!result.destination) return;
  //   const sourceIndex = result.source.index;
  //   const destinationIndex = result.destination.index;
  //   if (sourceIndex === destinationIndex) return;
  //   const updatedValues = [...formValues];
  //   const [draggedItem] = updatedValues.splice(sourceIndex, 1);
  //   updatedValues.splice(destinationIndex, 0, draggedItem);
  //   updatedValues.forEach((item, index) => {
  //     item.sequence = index + 1;
  //   });
  //   console.log('updatedValues', updatedValues);
  //   // setFormValues(updatedValues);
  //   // const formFieldValues = form.getFieldsValue(true); // Retrieve current form values
  //   const updatedFormValues = {};
  //   updatedValues.forEach((item) => {
  //     console.log('items', item);
  //     // const userFieldName = `user_id${item.sequence}`;
  //     // const designationFieldName = `designation_id${item.sequence}`;
  //     // updatedFormValues[userFieldName] =
  //     //   formFieldValues[`user_id${item.sequence}`] || item.user_id || undefined;
  //     // updatedFormValues[designationFieldName] =
  //     //   formFieldValues[`designation_id${item.sequence}`] ||
  //     //   item.designation_id ||
  //     //   undefined;
  //     updatedFormValues[`user_id${item.sequence}`] = item.user_id || undefined;
  //     updatedFormValues[`designation_id${item.sequence}`] =
  //       item.designation_id || undefined;
  //   });
  //   setFormValues(updatedValues);
  //   form.setFieldsValue(updatedFormValues);

  //   console.log('updatedValues:', updatedValues);
  // };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    if (sourceIndex === destinationIndex) return;

    const updatedValues = [...formValues];

    // Reorder the dragged item in the array
    const [draggedItem] = updatedValues.splice(sourceIndex, 1);
    updatedValues.splice(destinationIndex, 0, draggedItem);

    // Update the sequence numbers
    const updatedValuesWithNewSequences = updatedValues.map((item, index) => ({
      ...item,
      sequence: index + 1
    }));

    setFormValues(updatedValuesWithNewSequences);
    const updatedFormValues = {};
    updatedValuesWithNewSequences.forEach((item) => {
      const fieldName1 = `user_id${item.sequence}`;
      const fieldName = `designation_id${item.sequence}`;
      updatedFormValues[fieldName1] =
        item.user_id === '' ? undefined : item.user_id;
      updatedFormValues[fieldName] =
        item.designation_id === '' ? undefined : item.designation_id;
    });
    form.setFieldsValue(updatedFormValues);
  };

  // const handleDragEnd = (result) => {
  //   if (!result.destination) return;
  //   const updatedValues = Array.from(formValues);
  //   const [draggedItem] = updatedValues.splice(result.source.index, 1);
  //   updatedValues.splice(result.destination.index, 0, draggedItem);

  //   updatedValues.forEach((item, index) => {
  //     item.sequence = index + 1;
  //   });

  //   setFormValues(updatedValues);
  // };

  //

  const handleCategorySearch = (e) => {
    setCategorySearch(e);
  };

  const handleSubCategorySearch = (e) => {
    setSubSearch(e);
  };

  const handleGetCategories = () => {
    const payload = categorySearch != '' ? { search: categorySearch } : {};
    dispatch(getAllCategories(payload));
  };

  const onChangeCategory = (id) => {
    setSelectedCategoryId(id);
    handleGetSubCategories(id);
    form.setFieldsValue({ sub_category_id: undefined });
    setIsProjectActive(null);
    form.setFieldsValue({ project_id: undefined });
  };

  const handleGetSubCategories = (id) => {
    const payload =
      subSearch != ''
        ? { sub_search: subSearch, category_id: id }
        : { category_id: id };
    dispatch(getsubCategory(payload?.category_id));
  };

  useEffect(() => {
    handleGetSubCategories(selectedCategoryId); // Fetch subcategories when subSearch changes.
  }, [subSearch, selectedCategoryId]);

  useEffect(() => {
    handleGetCategories();
  }, [categorySearch]);

  // const onChangeDesignation = (data, index) => {
  //   const newState = formValues.map((obj) => {
  //     if (obj.sequence === index + 1) {
  //       return { ...obj, sequence: index + 1, user_id: data };
  //     }
  //     return obj;
  //   });

  //   setFormValues(newState);
  // };

  const handleDesignationSelect = (designationId, sequence, index) => {
    console.log('data', sequence);
    const newState = formValues.map((obj) => {
      if (obj.sequence === index + 1) {
        return { ...obj, sequence: index + 1, designation_id: designationId };
      }
      return obj;
    });

    setFormValues(newState);
    // setFormValues([...formValues, { designation_id: designationId }]);

    console.log('output', designationId);
    // Find the selected designation
    const selectedDesignation = get_designationDetails.find(
      (designation) => designation.id === designationId
    );
    // console.log('selectedDesignation', selectedDesignation);
    const usersForDesignation = selectedDesignation?.users || [];

    if (usersForDesignation.length > 0) {
      setFilteredUsersByRow((prev) => ({
        ...prev,
        [sequence]: usersForDesignation
      }));

      setRowErrors((prev) => ({
        ...prev,
        [sequence]: null
      }));
    } else {
      setFilteredUsersByRow((prev) => ({
        ...prev,
        [sequence]: []
      }));

      // Set an error message for this row
      setRowErrors((prev) => ({
        ...prev,
        [sequence]: 'No users found for the selected designation.'
      }));
    }
  };
  console.log('filteredUsersByRow', filteredUsersByRow);
  const Bread = () => {
    return (
      <div className={`${styles.breadcrub_container}`}>
        <Row>
          <Col span={19}>
            <Breadcrumb separator={<RightOutlined />}>
              <Breadcrumb.Item href="/settings">Settings</Breadcrumb.Item>

              <Breadcrumb.Item
                className="sfprotext-medium font-14"
                href="/settings/doa-flows"
              >
                Configure DOA Flows
              </Breadcrumb.Item>

              <Breadcrumb.Item
                className="sfprotext-medium font-14"
                href="/settings/create-doa-flow"
              >
                Create New Flow
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col className="text-right" span={4}>
            <Button
              htmlType="submit"
              className="font-14 sfprotext-bold "
              type="primary"
            >
              Save
            </Button>
          </Col>
        </Row>
      </div>
    );
  };

  useEffect(() => {
    handlegetPlants();
    handlegetfunctions();
    handlegetcategory();
    handlegetactivity();
    handlegetDesignations();
  }, []);

  const handlegetPlants = () => {
    dispatch(getPlants());
  };

  const handlegetfunctions = () => {
    dispatch(getfunctions());
  };

  const handlegetcategory = () => {
    dispatch(getcategory());
  };

  const handlegetactivity = () => {
    dispatch(getactivity());
  };

  const handlegetDesignations = (payload) => {
    dispatch(getAllDesignations(payload));
  };

  const debouncedhandleDesignationSearchChange = debounce((e) => {
    setDesignationSearch(e);
  }, 600);

  const handleDesignationSearchChange = (e) => {
    debouncedhandleDesignationSearchChange(e); // Call the debounced function
  };

  console.log('page', page);
  useEffect(() => {
    if (designationSearch !== '') {
      let payload = { search: designationSearch }; // Adjust payload as needed
      console.log('Fetching with payload:', payload);
      // Fetch designations with debouncing (optional)
      const debouncedFetch = debounce(handlegetDesignations, 300);
      debouncedFetch(payload);
    }
  }, [designationSearch]);

  return (
    <CustomLayout sider={false}>
      <Form
        onFinish={onFinish}
        form={form}
        layout="vertical"
        className="ant-form ant-form-vertical"
        // initialValues={{ approvers: [''] }}
        initialValues={{ approvers: formValues }}
        // onValuesChange={(changedValues, allValues) => {
        //   const updatedApprovers = allValues.approvers || [];
        //   setFormValues(
        //     updatedApprovers.map((item, index) => ({
        //       ...item,
        //       sequence: index + 1
        //     }))
        //   );
        // }}
      >
        <Bread />
        <div className="mt-3">
          <Card className={`${styles.newForm}`} hoverable>
            <Row>
              <Col className="font-16 sfprotext-bold mb-4" span={24}>
                Select Flow Details
              </Col>
              {/* Title and Plant */}

              <Col className="mr-5" xs={24} xl={11} span={11}>
                <FormItem
                  name="title"
                  label="Title"
                  rules={[
                    {
                      required: true,
                      message: 'Please select'
                    }
                  ]}
                >
                  <Input placeholder="Enter title" />
                </FormItem>
              </Col>
              <Col className="" xs={24} xl={11} span={11}>
                <FormItem
                  name="plant_id"
                  label="Plant"
                  rules={[
                    {
                      required: true,
                      message: 'Please plant'
                    }
                  ]}
                >
                  <Select
                    filterOption={(inputValue, option) =>
                      option.props.children
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                    onSearch={handleSubCategorySearch}
                    showSearch
                    suffixIcon={<CaretDownFilled />}
                    placeholder="Select Plant"
                  >
                    {get_plants
                      // ?.filter((item) => item.new_sub_cat_doa)
                      ?.map((item, index) => (
                        <Option key={index} value={item.id}>
                          {item.is_active === false
                            ? `${item.name} (Deactivated)`
                            : item.name}
                        </Option>
                      ))}
                  </Select>
                </FormItem>
              </Col>
              {/* Title and Plant End */}

              <Col className="mr-5" xs={24} xl={11} span={11}>
                <FormItem
                  name="category_id"
                  label="Category"
                  rules={[
                    {
                      required: true,
                      message: 'Please select'
                    }
                  ]}
                >
                  <Select
                    filterOption={(inputValue, option) =>
                      option.props.children
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                    onSearch={handleCategorySearch}
                    showSearch
                    suffixIcon={<CaretDownFilled />}
                    onChange={onChangeCategory}
                    placeholder="Select Category"
                  >
                    {' '}
                    {get_category
                      // ?.filter((item) => item.new_cat_doa)
                      .map((item, index) => (
                        <Option key={index} value={item.id}>
                          {item.is_active === false
                            ? `${item.category_name} (Deactivated)`
                            : item.category_name}
                        </Option>
                      ))}
                  </Select>
                </FormItem>
              </Col>
              <Col className="" xs={24} xl={11} span={11}>
                <FormItem
                  name="sub_category_id"
                  label="Sub-Category"
                  rules={[
                    {
                      required: true,
                      message: 'Please select'
                    }
                  ]}
                >
                  <Select
                    filterOption={(inputValue, option) =>
                      option.props.children
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                    onSearch={handleSubCategorySearch}
                    showSearch
                    suffixIcon={<CaretDownFilled />}
                    placeholder="Select Sub-Category"
                  >
                    {get_subcategorylist
                      // ?.filter((item) => item.new_sub_cat_doa)
                      ?.map((item, index) => (
                        <Option key={index} value={item.id}>
                          {item.is_active === false
                            ? `${item.sub_category_name} (Deactivated)`
                            : item.sub_category_name}
                        </Option>
                      ))}
                  </Select>
                </FormItem>
              </Col>

              {/* Department and Activity */}
              <Col className="mr-5" xs={24} xl={11} span={11}>
                <FormItem
                  name="department_id"
                  label="Department"
                  rules={[
                    {
                      required: true,
                      message: 'Please department'
                    }
                  ]}
                >
                  <Select
                    filterOption={(inputValue, option) =>
                      option.props.children
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                    onSearch={handleSubCategorySearch}
                    showSearch
                    suffixIcon={<CaretDownFilled />}
                    placeholder="Select Department"
                  >
                    {get_functions
                      // ?.filter((item) => item.new_sub_cat_doa)
                      ?.map((item, index) => (
                        <Option key={index} value={item.id}>
                          {item.is_active === false
                            ? `${item.name} (Deactivated)`
                            : item.function_name}
                        </Option>
                      ))}
                  </Select>
                </FormItem>
              </Col>
              <Col className="" xs={24} xl={11} span={11}>
                <FormItem
                  name="activity_id"
                  label="Activity"
                  rules={[
                    {
                      required: true,
                      message: 'Please select activity'
                    }
                  ]}
                >
                  <Select
                    filterOption={(inputValue, option) =>
                      option.props.children
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                    onSearch={handleSubCategorySearch}
                    showSearch
                    suffixIcon={<CaretDownFilled />}
                    placeholder="Select activity"
                  >
                    {get_activity
                      // ?.filter((item) => item.new_sub_cat_doa)
                      ?.map((item, index) => (
                        <Option key={index} value={item.id}>
                          {item.is_active === false
                            ? `${item.name} (Deactivated)`
                            : item.name}
                        </Option>
                      ))}
                  </Select>
                </FormItem>
              </Col>

              {/* Department and Activiti End */}

              <Col
                className={`${isProjectActive ? 'ml-5' : 'mr-5'}`}
                xs={24}
                xl={11}
                span={11}
              >
                <FormItem
                  name="min_price"
                  label="Value (Min)"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter a value'
                    }
                  ]}
                >
                  <InputNumber
                    precision={0}
                    disabled={anyValue}
                    className="w-100"
                    placeholder="Enter Min Budget"
                  />
                </FormItem>
              </Col>
              <Col className="" xs={24} xl={11} span={11}>
                <FormItem
                  name="max_price"
                  label="Value (Max)"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter a value'
                    }
                  ]}
                >
                  <InputNumber
                    precision={0}
                    disabled={anyValue}
                    className="w-100"
                    placeholder="Enter Max Budget"
                  />
                </FormItem>
              </Col>
              <Col span={24}>
                <Checkbox
                  className="mb-3"
                  onChange={toggleCheckbox}
                  value={anyValue}
                  type="checkbox"
                />{' '}
                <span>I want to mark the budget as {`"Any Value"`}</span>
              </Col>
            </Row>
          </Card>

          {/* Add user Card body */}

          <Card className={`mt-3 ${styles.newForm}`} hoverable>
            <Col className="mr-5" xs={24} xl={11} span={11}></Col>
            <Col className="font-16 sfprotext-bold mb-4" span={24}>
              Add Approvers
            </Col>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="formItems">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {formValues.map((formItem, index) => (
                      <Draggable
                        key={formItem.sequence} // Use sequence as the key
                        draggableId={formItem.sequence.toString()}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                          >
                            <Row
                              gutter={{
                                xs: 8,
                                sm: 16,
                                md: 24,
                                lg: 32
                              }}
                            >
                              {/* Step Indicator */}
                              <Col className="gutter-row" span={1}>
                                <div
                                  className={`${styles.step} ${styles.v_stepper} mt-2`}
                                >
                                  <div className={`${styles.circle}`}>
                                    <p>{index + 1}</p>
                                  </div>
                                  <div
                                    className={
                                      formValues.length !== index + 1 &&
                                      !snapshot.isDragging
                                        ? `${styles.line}`
                                        : ''
                                    }
                                  ></div>
                                </div>
                              </Col>

                              {/* Drag Handle */}
                              <Col className="gutter-row" xs={1} span={1}>
                                <div className="mt-2">
                                  <HolderOutlined />
                                </div>
                              </Col>

                              {/* Designation Dropdown */}
                              <Col
                                xs={11}
                                xl={8}
                                className="gutter-row"
                                span={6}
                              >
                                <FormItem
                                  name={`designation_id${formItem.sequence}`}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please select a designation'
                                    }
                                  ]}
                                >
                                  <Select
                                    placeholder="Select Designation"
                                    showSearch
                                    onSearch={handleDesignationSearchChange}
                                    suffixIcon={<CaretDownOutlined />}
                                    // onSelect={
                                    //   (value) =>
                                    //     onChangeDesignation(value, index) // Corrected parameter order
                                    // }
                                    onSelect={(value) =>
                                      handleDesignationSelect(
                                        value,
                                        formItem.sequence,
                                        index
                                      )
                                    } // Updated handler
                                  >
                                    {get_designationDetails?.map(
                                      (designation) => (
                                        <Option
                                          key={designation.id}
                                          value={designation.id}
                                        >
                                          {designation.is_active === false
                                            ? `${designation.designation} (Deactivated)`
                                            : designation.designation}
                                        </Option>
                                      )
                                    )}
                                  </Select>
                                </FormItem>
                              </Col>

                              {/* User Dropdown */}
                              <Col
                                xs={11}
                                xl={8}
                                className="gutter-row"
                                span={6}
                              >
                                <FormItem
                                  name={`user_id${formItem.sequence}`}
                                  rules={[
                                    {
                                      required:
                                        !!filteredUsersByRow[formItem.sequence]
                                          ?.length,
                                      message:
                                        rowErrors[formItem.sequence] ||
                                        'Please select a user'
                                    }
                                  ]}
                                >
                                  <Select
                                    placeholder="Select User"
                                    showSearch
                                    labelInValue
                                    onSearch={handleDesignSearchChange}
                                    disabled={
                                      !filteredUsersByRow[formItem.sequence]
                                        ?.length
                                    }
                                    suffixIcon={<CaretDownFilled />}
                                    filterOption={(inputValue, option) =>
                                      option.props.children
                                        .toUpperCase()
                                        .indexOf(inputValue.toUpperCase()) !==
                                      -1
                                    }
                                    onSelect={
                                      (value) =>
                                        onChangeDesignation(value, index) // Corrected parameter order
                                    }
                                  >
                                    {(
                                      filteredUsersByRow[formItem.sequence] ||
                                      []
                                    ).map((user) => (
                                      <Option key={user.id} value={user.id}>
                                        {user.is_active === false
                                          ? `${user.display_name} (${user.email}) (Deactivated)`
                                          : `${user.display_name} (${user.email})`}
                                      </Option>
                                    ))}
                                  </Select>
                                </FormItem>
                                {rowErrors[formItem.sequence] && (
                                  <p style={{ color: 'red' }}>
                                    {rowErrors[formItem.sequence]}
                                  </p>
                                )}
                              </Col>

                              {/* Delete Button */}
                              <Col
                                className="gutter-row"
                                xs={3}
                                xl={6}
                                span={6}
                              >
                                <div>
                                  {index > 0 && (
                                    <DeleteOutlined
                                      onClick={() =>
                                        handleRemoveFormItem(formItem.sequence)
                                      }
                                      className={`cursor-pointer ${styles.delete_icon}`}
                                    />
                                  )}
                                </div>
                              </Col>
                            </Row>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            {/* Add Approver Button */}
            <span
              onClick={addInput}
              className={`cursor-pointer ${styles.span_add}`}
            >
              <PlusCircleFilled />
              {'\u00a0'} Add Roles
            </span>
          </Card>

          {/* Add user Card body END */}
        </div>
      </Form>
    </CustomLayout>
  );
};

export default CreateDOAPage;
