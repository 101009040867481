import CustomButton from '@/common/CustomButton';
import CustomModal from '@/common/CustomModal';
import FormItem from '@/common/FormItem';
import { editActivity } from '@/pages/settings/redux/slice';
//  createCategory
import { Col, Form, Input, Row, Skeleton, Switch, message } from 'antd';
import styles from './index.module.less';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
//import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

const EditModal = ({
  byIdLoading,
  setId,
  id,
  // category_by_id,
  getActivityId,
  onCancel,
  isEditModalVisible,
  setIsEditModalOpen,
  handleGetActivity
}) => {
  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState(getActivityId?.is_active);
  // const [checked, setChecked] = useState(false);

  // const handleChange = (checked) => {
  //   setChecked(checked);
  // };
  const [form] = Form.useForm();

  useEffect(() => {
    setId(getActivityId?.id);
    setIsActive(getActivityId?.is_active);
    /*  setAppId(flowData.app_id); */
    form.setFieldsValue({
      action: getActivityId?.is_active,
      name: getActivityId?.name
    });
  }, [getActivityId, form]);

  const onFinish = (values) => {
    values['id'] = id;
    values['action'] = isActive ? 'activate' : 'deactivate';
    dispatch(editActivity(values)).then((response) => {
      if (response.payload.success) {
        message.success(response.payload.message);
        setIsEditModalOpen(false);
        handleGetActivity();
      } else {
        message.error(response.payload.message);
      }
    });
  };
  const handleSwitchChange = (checked) => {
    setIsActive(checked);
  };
  return (
    <CustomModal
      footer={
        <Form
          onFinish={onFinish}
          form={form}
          layout="vertical"
          className="ant-form ant-form-vertical"
        >
          <Row>
            <Col className="text-left" span={12}>
              <CustomButton onClick={onCancel}>Cancel</CustomButton>{' '}
            </Col>
            <Col className="text-right" span={12}>
              <CustomButton type="primary" htmlType="submit">
                Edit
              </CustomButton>
            </Col>
          </Row>
        </Form>
      }
      onCancel={onCancel}
      visible={isEditModalVisible}
      title="Edit Activity Details"
    >
      {byIdLoading ? (
        <Skeleton />
      ) : (
        <Form
          onFinish={onFinish}
          form={form}
          layout="vertical"
          className="ant-form ant-form-vertical"
        >
          <Row>
            <Col span={24}>
              <FormItem
                label={'Activity Name'}
                name="name"
                // className={`${styles.astrick}`}
                rules={[
                  {
                    required: true,
                    message: 'Please enter name'
                  }
                ]}
              >
                <Input placeholder="Enter Activity Name" />
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem
                label={'Status'}
                name="action"
                // className={`${styles.astrick}`}
                rules={[
                  {
                    required: true,
                    message: 'Please enter'
                  }
                ]}
              >
                <div
                  className={
                    isActive
                      ? `${styles.switcher_container}`
                      : `${styles.switcher_container1}`
                  }
                >
                  <Switch checked={isActive} onChange={handleSwitchChange} />
                </div>
              </FormItem>
            </Col>
          </Row>
        </Form>
      )}
    </CustomModal>
  );
};

export default EditModal;
